


import { Component, Vue } from "vue-property-decorator";

@Component
export default class BorrowerTermsAndConditionsComponent extends Vue {
  public openDetails = {
    first: false,
    second: false,
    third: false,
    fourth: false
  };

  scrollToTop() {
    window.scrollTo({
      top: 200,
      behavior: 'smooth',
    });
  }

  public changeOpenBox(item) {
    this.openDetails[item] = !this.openDetails[item];
    Object.keys(this.openDetails).forEach(e => {
      let panel: any = this.$refs[e];
      if (e != item) {
        this.openDetails[e] = false;
        panel.style.maxHeight = null;
      } else {
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      }
    });
  }
}
